<template>
  <div id="app-university-category" class="app-add">
    <div
        v-loading="form.loading"
        element-loading-text="提交中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.1)"
        style="width:100px; left:42%; top:46%; position: absolute"></div>

    <el-form :model="category" label-width="100px">
      <el-form-item label="院校名称" prop="name">
        <el-input v-model="category.name" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="排序">
        <el-input type="number" v-model="category.sort" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item style="display: none">
        <el-button @click="submit('form')" id="layuiadmin-app-form-submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { UrlEnum,TipEnum } from "../../../../../public/js/common-vue";
import { request } from "../../../../../public/js/common-vue";

export default {
  data() {
    return {
      form:{
        loading: false
      },
      category: {}
    }
  },
  methods: {
    initData: function () {
      this.$http({
        method: "get",
        url: UrlEnum.UNIVERSITY_CATEGORIES + "/" + request('id'),
      }).then((res) => {
        this.category = res.data;
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    submit: function(){
      console.log(JSON.stringify(this.category));
      if(this.category.name==""){
        this.showMsgError("请输入院校名称！")
        return false;
      }
      this.form.loading=true;
      this.$http({
        method: "put",
        url: UrlEnum.UNIVERSITY_CATEGORIES + "/" + request('id'),
        data: this.category,
      })
      .then((res) => {
        this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
        const index = parent.layer.getFrameIndex(window.name); //先得到当前iframe层的索引
        setTimeout(function () {
          parent.layui.table.reload('tableData'); //重载表格
          parent.layer.close(index); //再执行关闭
        }, 800);
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    }
  },
  mounted() {
    this.initData();
  }
}
</script>

<style scoped>
@import "../../../../../static/css/add.css";
</style>
